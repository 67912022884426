import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
	"zh": {
		head: {
			t1: "链接钱包",
			t2: "钱包地址复制成功",
			t3: "断开链接",
			t4: "是否断开钱包链接？",
			t5: "取消",
			t6: "确认",
		},
		methods: {
			t0: "未检测到钱包，请刷新页面后再试！",
			t1: "余额检查中...",
			t2: "代币余额不足",
			t3: '余额充足，检查授权',
			t4: '代币授权中...',
			t5: '授权成功',
			t6: '授权失败',
			t7: "执行合约中...",
			t8: '订单不存在',
			t9: '不能购买自己的商品',
			t10: '余额检查中...',
			t11: '代币余额不足',
			t12: '验证中',
			t13: '订单已取消',
			t14: '只能撤销自己的订单',
			t15: '执行合约',
			t16: '用户拒绝了交易',
			t17: '调用合约时发生错误',
			t18: '账户余额不足，无法完成交易',
			t19: '授权额度不足，请增加授权额度',
			t20: '提供的交易金额不足，无法完成交易',
			t21: '合约执行失败，参数错误',
			t22: '无法估算 Gas 限制，请手动设置 gas limit',
			t23: '网络连接异常，请检查您的网络连接',
			t24: '请求超时，请稍后重试',
			t25: '交易已过期，请重试',
			t26: '交易的 gas 价格过低，请提高 gas 价格',
			t27: '交易已被替换',
			t28: '交易耗尽 Gas，请增加 Gas 限制',
			t29: '签名无效，请检查签名参数',
			t30: '交易失败'
		},
		home: {
			t1: "参数检查",
			t2: "我要挂单",
			t3: "24小时交易量",
			t4: "24小时最高价",
			t5: "24小时最低价",
			t6: "市场",
			t7: "交易动态",
			t8: "我的订单",
			t9: "行情",
			t10: "搜索",
			t11: "币种",
			t12: "最新价",
			t13: "涨跌幅",
			t14: "暂无数据",
			t15: "我要挂单",
			t16: "挂单数量",
			t17: "余额：",
			t18: "挂单单价",
			t19: "预计收益",
			t20: "手续费",
			t21: "支付总价",
			t22: "取消",
			t23: "确认",
			t24: "确认购买",
			t25: "到账地址",
			t26: "购买数量",
			t27: "单价",
			t28: "手续费",
			t29: "支付总价",
			t30: "取消",
			t31: "确认",
			t32: "加载中...",
			t33: "网络异常",
			t34: "请输入挂单数量和单价",
			t35: "代币余额不足",
			t36: "参数检查...",
			t37: "挂单成功",
			t38: "挂单失败",
			t39: "挂单失败",
			t40: "余额检查...",
			t41: "购买成功",
			t42: "购买失败",
			t43: "购买失败",
			t44: "添加成功",
			t45: "添加失败",
			t46: "代币信息",
			t47: "代币名称",
			t48: "代币合约",
			t49: "发行量",
			t50: "关闭",
			t51: "添加到钱包",
			t52: "下拉即可刷新...",
			t53: "释放即可刷新...",
			t54: "加载中...",
			t55: "全部",
		},
		market: {
			t1: "价格优先",
			t2: "数量优先",
			t3: "编号",
			t4: "单价",
			t5: "购买",
			t6: "暂无数据",
		},
		trade: {
			t1: "成交",
			t2: "总价",
			t3: "编号",
			t4: "时间",
			t5: "交易Hash",
			t6: "暂无数据",
		},
		order: {
			t1: "当前委托",
			t2: "历史委托",
			t3: "购买订单",
			t4: "编号",
			t5: "挂单时间",
			t6: "交易时间",
			t7: "交易Hash",
			t8: "已完成",
			t9: "已取消",
			t10: "撤回订单",
			t11: "暂无数据",
			t12: "挂单Hash",
			t13: "成交Hash",
			t14: "撤单Hash",
		}
	},
	"zh-hant": {
		head: {
			t1: "連接錢包",
			t2: "錢包地址複製成功",
			t3: "斷開連接",
			t4: "是否斷開錢包連接？",
			t5: "取消",
			t6: "確認"
		},
		methods: {
			t0: "未檢測到錢包，請刷新頁面後再試！",
			t1: "餘額檢查中...",
			t2: "代幣餘額不足",
			t3: "餘額充足，檢查授權",
			t4: "代幣授權中...",
			t5: "授權成功",
			t6: "授權失敗",
			t7: "執行合約中...",
			t8: "訂單不存在",
			t9: "不能購買自己的商品",
			t10: "餘額檢查中...",
			t11: "代幣餘額不足",
			t12: "驗證中",
			t13: "訂單已取消",
			t14: "只能撤銷自己的訂單",
			t15: "執行合約",
			t16: "用戶拒絕了交易",
			t17: "調用合約時發生錯誤",
			t18: "賬戶餘額不足，無法完成交易",
			t19: "授權額度不足，請增加授權額度",
			t20: "提供的交易金額不足，無法完成交易",
			t21: "合約執行失敗，參數錯誤",
			t22: "無法估算 Gas 限制，請手動設置 gas limit",
			t23: "網絡連接異常，請檢查您的網絡連接",
			t24: "請求超時，請稍後重試",
			t25: "交易已過期，請重試",
			t26: "交易的 gas 價格過低，請提高 gas 價格",
			t27: "交易已被替換",
			t28: "交易耗盡 Gas，請增加 Gas 限制",
			t29: "簽名無效，請檢查簽名參數",
			t30: "交易失敗"
		},
		home: {
			t1: "參數檢查",
			t2: "我要掛單",
			t3: "24小時交易量",
			t4: "24小時最高價",
			t5: "24小時最低價",
			t6: "市場",
			t7: "交易動態",
			t8: "我的訂單",
			t9: "行情",
			t10: "搜索",
			t11: "幣種",
			t12: "最新價",
			t13: "漲跌幅",
			t14: "暫無數據",
			t15: "我要掛單",
			t16: "掛單數量",
			t17: "餘額：",
			t18: "掛單單價",
			t19: "預計收益",
			t20: "手續費",
			t21: "支付總價",
			t22: "取消",
			t23: "確認",
			t24: "確認購買",
			t25: "到賬地址",
			t26: "購買數量",
			t27: "單價",
			t28: "手續費",
			t29: "支付總價",
			t30: "取消",
			t31: "確認",
			t32: "加載中...",
			t33: "網絡異常",
			t34: "請輸入掛單數量和單價",
			t35: "代幣餘額不足",
			t36: "參數檢查...",
			t37: "掛單成功",
			t38: "掛單失敗",
			t39: "掛單失敗",
			t40: "餘額檢查...",
			t41: "購買成功",
			t42: "購買失敗",
			t43: "購買失敗",
			t44: "添加成功",
			t45: "添加失敗",
			t46: "代幣資訊",
			t47: "代幣名稱",
			t48: "代幣合約",
			t49: "發行量",
			t50: "關閉",
			t51: "添加到錢包",
			t52: "下拉即可刷新...",
			t53: "釋放即可刷新...",
			t54: "加載中...",
			t55: "全部",
		},
		market: {
			t1: "價格優先",
			t2: "數量優先",
			t3: "編號",
			t4: "單價",
			t5: "購買",
			t6: "暫無數據"
		},
		trade: {
			t1: "成交",
			t2: "總價",
			t3: "編號",
			t4: "時間",
			t5: "交易Hash",
			t6: "暫無數據"
		},
		order: {
			t1: "當前委託",
			t2: "歷史委託",
			t3: "購買訂單",
			t4: "編號",
			t5: "掛單時間",
			t6: "交易時間",
			t7: "交易Hash",
			t8: "已完成",
			t9: "已取消",
			t10: "撤回訂單",
			t11: "暫無數據",
			t12: "掛單Hash",
			t13: "成交Hash",
			t14: "撤單Hash"
		}
	},
	"en": {
		head: {
			t1: "Connect Wallet",
			t2: "Wallet address copied successfully",
			t3: "Disconnect",
			t4: "Do you want to disconnect the wallet?",
			t5: "Cancel",
			t6: "Confirm"
		},
		methods: {
			t0: "Wallet not detected, please refresh the page and try again!",
			t1: "Checking balance...",
			t2: "Insufficient token balance",
			t3: "Balance sufficient, checking authorization",
			t4: "Authorizing token...",
			t5: "Authorization successful",
			t6: "Authorization failed",
			t7: "Executing contract...",
			t8: "Order does not exist",
			t9: "Cannot buy your own item",
			t10: "Checking balance...",
			t11: "Insufficient token balance",
			t12: "Validating",
			t13: "Order cancelled",
			t14: "Can only cancel your own order",
			t15: "Executing contract",
			t16: "User rejected the transaction",
			t17: "Error occurred while calling contract",
			t18: "Insufficient account balance, unable to complete transaction",
			t19: "Authorization limit insufficient, please increase the limit",
			t20: "Transaction amount provided is insufficient, unable to complete transaction",
			t21: "Incorrect parameter passed, please check input",
			t22: "Unable to estimate gas limit, please manually set gas limit",
			t23: "Network connection error, please check your connection",
			t24: "Request timed out, please try again later",
			t25: "Transaction expired, please retry",
			t26: "Gas price too low, please increase gas price",
			t27: "Transaction has been replaced",
			t28: "Transaction ran out of gas, please increase gas limit",
			t29: "Invalid signature, please check signature parameters",
			t30: "Transaction failed"
		},
		home: {
			t1: "Parameter Check",
			t2: "Place an Order",
			t3: "24h Trading Volume",
			t4: "24h Highest Price",
			t5: "24h Lowest Price",
			t6: "Market",
			t7: "Trade Activity",
			t8: "My Orders",
			t9: "Market Data",
			t10: "Search",
			t11: "Token",
			t12: "Latest Price",
			t13: "Price Change",
			t14: "No data available",
			t15: "Place an Order",
			t16: "Order Quantity",
			t17: "Balance:",
			t18: "Order Price",
			t19: "Estimated Earnings",
			t20: "Fee",
			t21: "Total Payment",
			t22: "Cancel",
			t23: "Confirm",
			t24: "Confirm Purchase",
			t25: "Receiving Address",
			t26: "Purchase Quantity",
			t27: "Price per Unit",
			t28: "Fee",
			t29: "Total Payment",
			t30: "Cancel",
			t31: "Confirm",
			t32: "Loading...",
			t33: "Network Error",
			t34: "Please enter order quantity and price",
			t35: "Insufficient token balance",
			t36: "Checking parameters...",
			t37: "Order placed successfully",
			t38: "Order placement failed",
			t39: "Order placement failed",
			t40: "Checking balance...",
			t41: "Purchase successful",
			t42: "Purchase failed",
			t43: "Purchase failed",
			t44: "successfully",
			t45: "Add failed",
			t46: "Token Information",
			t47: "Token Name",
			t48: "Token Contract",
			t49: "Total Supply",
			t50: "Close",
			t51: "Add to Wallet",
			t52: "Pull down to refresh...",
			t53: "Release to refresh...",
			t54: "Loading...",
			t55: "All",
		},
		market: {
			t1: "Price Priority",
			t2: "Quantity Priority",
			t3: "ID",
			t4: "Price per Unit",
			t5: "Buy",
			t6: "No data available"
		},
		trade: {
			t1: "Transaction",
			t2: "Total Price",
			t3: "ID",
			t4: "Time",
			t5: "Transaction Hash",
			t6: "No data available"
		},
		order: {
			t1: "Current Orders",
			t2: "Order History",
			t3: "Purchase Orders",
			t4: "ID",
			t5: "Order Time",
			t6: "Transaction Time",
			t7: "Transaction Hash",
			t8: "Completed",
			t9: "Cancelled",
			t10: "Withdraw Order",
			t11: "No data available",
			t12: "Order Hash",
			t13: "Transaction Hash",
			t14: "Cancellation Hash",
		}
	},
	"ja": {
		head: {
			t1: "ウォレットを接続",
			t2: "ウォレットアドレスが正常にコピーされました",
			t3: "接続を解除",
			t4: "ウォレット接続を解除しますか？",
			t5: "キャンセル",
			t6: "確認"
		},
		methods: {
			t0: "ウォレットが検出されませんでした。ページを更新してから試してください！",
			t1: "残高を確認中...",
			t2: "トークン残高が不足しています",
			t3: "残高が十分です。認証を確認中",
			t4: "トークンを認証中...",
			t5: "認証成功",
			t6: "認証失敗",
			t7: "コントラクトを実行中...",
			t8: "注文が存在しません",
			t9: "自分の商品は購入できません",
			t10: "残高を確認中...",
			t11: "トークン残高が不足しています",
			t12: "検証中",
			t13: "注文がキャンセルされました",
			t14: "自分の注文のみキャンセルできます",
			t15: "コントラクトを実行",
			t16: "ユーザーが取引を拒否しました",
			t17: "コントラクト呼び出し中にエラーが発生しました",
			t18: "口座残高が不足しているため、取引を完了できません",
			t19: "認証限度が不足しています。限度を増やしてください",
			t20: "提供された取引金額が不足しているため、取引を完了できません",
			t21: "渡されたパラメーターが間違っています。入力を確認してください",
			t22: "ガスリミットを推定できません。手動でガスリミットを設定してください",
			t23: "ネットワーク接続に異常があります。接続を確認してください",
			t24: "リクエストがタイムアウトしました。しばらくしてからもう一度お試しください",
			t25: "取引が期限切れです。再試行してください",
			t26: "ガス価格が低すぎます。ガス価格を上げてください",
			t27: "取引が置き換えられました",
			t28: "ガスが不足しました。ガスリミットを増やしてください",
			t29: "署名が無効です。署名パラメータを確認してください",
			t30: "取引に失敗しました"
		},
		home: {
			t1: "パラメーター確認",
			t2: "注文を出す",
			t3: "24時間取引量",
			t4: "24時間最高価格",
			t5: "24時間最低価格",
			t6: "市場",
			t7: "取引アクティビティ",
			t8: "私の注文",
			t9: "市場データ",
			t10: "検索",
			t11: "通貨",
			t12: "最新価格",
			t13: "変動率",
			t14: "データがありません",
			t15: "注文を出す",
			t16: "注文数量",
			t17: "残高：",
			t18: "注文価格",
			t19: "予想利益",
			t20: "手数料",
			t21: "総支払額",
			t22: "キャンセル",
			t23: "確認",
			t24: "購入を確認",
			t25: "受取アドレス",
			t26: "購入数量",
			t27: "単価",
			t28: "手数料",
			t29: "総支払額",
			t30: "キャンセル",
			t31: "確認",
			t32: "読み込み中...",
			t33: "ネットワークエラー",
			t34: "注文数量と価格を入力してください",
			t35: "トークン残高が不足しています",
			t36: "パラメーター確認中...",
			t37: "注文成功",
			t38: "注文に失敗しました",
			t39: "注文に失敗しました",
			t40: "残高を確認中...",
			t41: "購入成功",
			t42: "購入失敗",
			t43: "購入失敗",
			t44: "正常に追加されました",
			t45: "追加に失敗しました",
			t46: "トークン情報",
			t47: "トークン名",
			t48: "トークンコントラクト",
			t49: "発行量",
			t50: "閉じる",
			t51: "ウォレットに追加",
			t52: "下に引っ張るとリフレッシュします...",
			t53: "離すとリフレッシュします...",
			t54: "読み込み中...",
			t55: "全て"
		},
		market: {
			t1: "価格優先",
			t2: "数量優先",
			t3: "ID",
			t4: "単価",
			t5: "購入",
			t6: "データがありません"
		},
		trade: {
			t1: "取引",
			t2: "総額",
			t3: "ID",
			t4: "時間",
			t5: "取引ハッシュ",
			t6: "データがありません"
		},
		order: {
			t1: "現在の注文",
			t2: "注文履歴",
			t3: "購入注文",
			t4: "ID",
			t5: "注文時間",
			t6: "取引時間",
			t7: "取引ハッシュ",
			t8: "完了",
			t9: "キャンセル済み",
			t10: "注文を撤回",
			t11: "データがありません",
			t12: "注文 Hash",
			t13: "取引 Hash",
			t14: "キャンセル Hash",
		}
	}

};

const i18n = new VueI18n({
	locale: 'zh-hant', // 默认语言
	fallbackLocale: 'zh-hant', // 未找到翻译时的默认语言
	messages
});

export default i18n;

<template>
	<div>
		<div class="sort">
			<!-- 价格优先 -->
			<span @click="changeSort" v-if="sort == 'price'">{{ $t("market.t1") }}</span>
			<!-- 数量优先 -->
			<span @click="changeSort" v-if="sort == 'amount'">{{ $t("market.t2") }}</span>
			<img src="../assets/sort.png" alt="" />
		</div>

		<div class="list" v-if="list.length > 0">
			<div class="item" v-for="(item, index) in list" :key="index">
				<div class="token">
					<div class="name">
						<img :src="this_token.tokenImg" alt="" />
						<span>{{ $utils.numToK(item.amount1, 4, 0) }}</span>
						<span>{{ this_token.name }}</span>
					</div>
					<span>{{ $utils.numToK(item.amount1 * item.price1, 8, 0) }} {{ this_token.pair_name }}</span>
				</div>
				<div class="price">
					<div>
						<div>
							<!-- 编号 -->
							<span>{{ $t("market.t3") }}</span>
							<span>#{{ handle_id(item.orderId) }}</span>
						</div>
						<div>
							<!-- 单价 -->
							<span>{{ $t("market.t4") }}</span>
							<span>{{ item.price1 }} {{ this_token.pair_name }} / {{ this_token.name }}</span>
						</div>
					</div>
					<!-- 购买 -->
					<van-button type="info" style="width: 82px" @click="showBuyPop(item)">{{ $t("market.t5") }}</van-button>
				</div>
			</div>
		</div>
		<div v-else class="none">
			<img src="../assets/no_record.png" />
			<!-- 暫無數據 -->
			<div>{{ $t("market.t6") }}</div>
		</div>
		<div class="loading_box" v-show="!finished" ref="loading_box">
			<van-loading type="spinner" size="16px" />
			<span>{{ $t("home.t54") }}</span>
		</div>
	</div>
</template>

<script>
import { Notify } from "vant-green";
export default {
	name: "MarketView",
	props: {
		tabIndex: Number,
	},
	data() {
		return {
			this_token: this.$store.state.this_token,
			list: [],
			sort: "price",

			timer: null,
			loading: false,
			finished: true,
			page: 1,
			offset: 100,
			h: 0,
			LoadingBox: null,
		};
	},
	async mounted() {
		//查询列表
		this.getData();
		//获取屏幕高度
		this.h = document.documentElement.clientHeight || document.body.clientHeight;
		this.LoadingBox = this.$refs["loading_box"];
		//监听滚动
		// window.removeEventListener("scroll", this.handleScroll);
		// window.addEventListener("scroll", this.handleScroll);
	},
	methods: {
		async changeSort() {
			this.$toast.loading({
				message: this.$t("home.t32"),
				forbidClick: true,
				duration: 0,
			});
			this.sort = this.sort == "price" ? "amount" : "price";
			await this.getData();
			this.$toast.clear();
		},
		handle_id(id) {
			//把id转为hash，不足6位前面补0
			return this.$utils.idComplex(id, 18);
		},
		showBuyPop(item) {
			this.$emit("openBuyPop", item);
		},
		async getData() {
			try {
				const result = await this.$api.request(
					"/get_market_list",
					{
						address: this.this_token.address,
						sort: this.sort,
						page: this.page,
						offset: this.offset,
					},
					"POST"
				);
				if (result.code == 200) {
					if (this.page == 1) {
						this.list = result.data;
						this.finished = false;
					} else {
						this.list = this.list.concat(result.data);
					}
					if (result.data.length < this.offset) {
						this.finished = true;
					}
				} else {
					Notify({
						type: "danger",
						message: result.msg,
					});
				}
			} catch (error) {
				console.log(error);
				Notify({
					type: "danger",
					message: this.$t("home.t33"),
				});
			}
		},
		async onRefresh(reload = true) {
			this.page = 1;
			this.finished = false;
			if (reload) {
				this.list = [];
			}
			this.this_token = this.$store.state.this_token;
			this.getData();
		},
		handleScroll(e) {
			if (this.tabIndex != 0) return;
			if (this.loading || this.finished) return;
			this.timer && clearTimeout(this.timer);
			this.timer = setTimeout(async () => {
				const rect = this.LoadingBox.getBoundingClientRect();
				//判断是否滚动到底部
				if (rect.bottom < this.h) {
					this.loading = true;
					this.page++;
					await this.getData();
					this.loading = false;
				}
			}, 300);
		},
	},
};
</script>

<style scoped lang="scss">
.sort {
	padding: 15px 0;
	display: flex;
	align-items: center;
	font-size: 12px;
	gap: 4px;
	img {
		width: 8px;
	}
}

.item {
	padding: 0 16px;
	margin-bottom: 5px;
	background-color: #fff;
	border-radius: 10px;

	.token {
		font-size: 16px;
		color: #030c03;
		font-weight: 500;
		border-bottom: solid 1px #e5e5e5;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.name {
			display: flex;
			align-items: center;
			gap: 7px;
			img {
				width: 40px;
				height: 40px;
				border-radius: 50%;
			}
		}
	}

	.price {
		min-height: 64px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 12px;
		color: #030c03;
		> div {
			display: flex;
			flex-direction: column;
			gap: 10px;
			line-height: 1;
			> div {
				display: flex;
				gap: 10px;
			}
		}
	}
}

.loading_box {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 20px;
	span {
		margin-left: 4px;
		font-size: 12px;
		color: #999;
	}
}
</style>

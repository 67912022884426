import { ethers } from 'ethers';
import ABI from '../../abi/ABI.json';
import ERC20ABI from '../../abi/ERC20ABI.json';
import { Notify } from 'vant-green';
import { Toast } from 'vant';
import i18n from './i18n.js';
import Big from 'big.js';

var ethereum = null;

class BlockchainContract {
    constructor(config) {
        if (window.ethereum) {
            ethereum = window.ethereum;
            console.log("Running in Ethereum");
        } else if (window.web3) {
            ethereum = window.web3.currentProvider;
            console.log("Running in Web3");
        } else {
            Notify({
                type: 'danger',
                message: '401:' + i18n.t("methods.t0")
            });
            throw new Error('401:' + i18n.t("methods.t0"));
        }

        this.markerContract = config.contract;
        this.provider = null;
        this.signer = null;
        this.contract = null;

        this.provider = new ethers.providers.Web3Provider(ethereum);
        this.signer = this.provider.getSigner();
        this.contract = new ethers.Contract(this.markerContract, ABI, this.signer);
    }
    // 大数转小数
    fromWei(num) {
        return ethers.utils.formatUnits(num.toString(), 'ether');
    }
    // 小数转大数
    toWei(num) {
        return ethers.utils.parseUnits(num.toString(), 'ether');
    }
    // 查询手续费
    async getFee() {
        try {
            const [sellFee, buyFee, feeDenominator] = await Promise.all([
                this.contract.sellFee(),
                this.contract.buyFee(),
                this.contract.feeDenominator()
            ]);
            return {
                sellFee: ethers.utils.formatUnits(sellFee, 18) / ethers.utils.formatUnits(feeDenominator, 18),
                buyFee: ethers.utils.formatUnits(buyFee, 18) / ethers.utils.formatUnits(feeDenominator, 18)
            };
        } catch (error) {
            this.errorHandle(error);
            throw error;
        }
    }
    // 查询代币余额
    async getBalance(account, token) {
        try {
            if (token == '0x0000000000000000000000000000000000000000') {
                //查询链币余额
                const rawTokens = await this.provider.getBalance(account);
                return ethers.utils.formatUnits(rawTokens, 18);
            } else {
                const TokenContract = new ethers.Contract(token, ERC20ABI, this.signer);
                const rawTokens = await TokenContract.balanceOf(account);
                return ethers.utils.formatUnits(rawTokens, 18);
            }
        } catch (error) {
            this.errorHandle(error);
            throw error;
        }
    }
    // 查询授权额度
    async getAllowance(tokenAddress) {
        try {
            const TokenContract = new ethers.Contract(tokenAddress, ERC20ABI, this.signer);
            const allowance = await TokenContract.allowance(await this.signer.getAddress(), this.markerContract);
            return ethers.utils.formatUnits(allowance, 'ether');
        } catch (error) {
            this.errorHandle(error);
            throw error;
        }
    }
    //添加代币
    async addToken(token_info, _this) {
        try {
            const wasAdded = await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: token_info.address,
                        symbol: token_info.name,
                        decimals: token_info.decimals,
                        image: window.location.origin + token_info.tokenImg,
                    },
                },
            });
            return wasAdded;
        } catch (error) {
            this.errorHandle(error);
        }
    }
    // 处理错误
    async errorHandle(error) {
        console.log(error.code);
        // console.log(error);
        if (error.code === 'ACTION_REJECTED') {
            // 用户拒绝交易
            Toast(i18n.t("methods.t16"));
        } else if (error.code === 'CALL_EXCEPTION') {
            // 合约调用异常
            Toast({
                message: i18n.t("methods.t17"),
                duration: 3000
            });
        } else if (error.code === 'INSUFFICIENT_FUNDS') {
            // 用户的链币余额不足
            Toast(i18n.t("methods.t18"));
        } else if (error.code === 'INSUFFICIENT_ALLOWANCE') {
            // 授权额度不足
            Toast(i18n.t("methods.t19"));
        } else if (error.code === 'INSUFFICIENT_VALUE') {
            // 提供的交易价值不足
            Toast(i18n.t("methods.t20"));
        } else if (error.code === 'INVALID_ARGUMENT') {
            // 传递的参数无效
            if (error.reason && error.reason == 'invalid hash') {
                Toast(i18n.t("methods.t16"));
            } else {
                Toast(i18n.t("methods.t21"));
            }
        } else if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
            // 无法预测 Gas 限制
            Toast({
                message: i18n.t("methods.t21") + "\n" + i18n.t("methods.t22"),
                duration: 3000
            });
        } else if (error.code === 'NETWORK_ERROR') {
            // 网络问题
            Toast(i18n.t("methods.t23"));
        } else if (error.code === 'TIMEOUT') {
            // 请求超时
            Toast(i18n.t("methods.t24"));
        } else if (error.code === 'NONCE_EXPIRED') {
            // 交易的 nonce 已过期
            Toast(i18n.t("methods.t25"));
        } else if (error.code === 'REPLACEMENT_UNDERPRICED') {
            // 交易 gas 价格过低
            Toast(i18n.t("methods.t26"));
        } else if (error.code === 'TRANSACTION_REPLACED') {
            // 交易被替换
            Toast(i18n.t("methods.t27"));
        } else if (error.code === 'OUT_OF_GAS') {
            // 交易因为耗尽 Gas 而失败
            Toast(i18n.t("methods.t28"));
        } else if (error.code === 'INVALID_SIGNATURE') {
            // 签名无效
            Toast(i18n.t("methods.t29"));
        } else {
            // 其他未知错误
            Toast({
                message: error.error && error.error.data && error.error.data.message ? error.error.data.message : error.message ? error.message : i18n.t("methods.t30"),
                duration: 3000
            });
        }
    }



    //判断代币余额和授权
    async checkBalanceAndApprove(token, payment_amount, account, _this) {
        //初始化合约
        const TokenContract = new ethers.Contract(token, ERC20ABI, this.signer);
        // 查询代币余额
        const balance = new Big(await TokenContract.balanceOf(account));
        // console.log(`balance insufficient：balance(${balance}, ${_this.token_info.name}) lt payment_amount(${payment_amount}, ${_this.token_info.name})`, balance.lt(payment_amount));
        console.log("余额：", Number(Big(balance).div(1e18).toString()), _this.token_info.name);
        console.log("需支付金额：", Number(Big(payment_amount).div(1e18)), _this.token_info.name);
        console.log("余额是否充足：", balance.gte(payment_amount));
        if (balance.lt(payment_amount)) {
            throw new Error(i18n.t("methods.t2"));  //代币余额不足
        }

        // 余额充足，检查授权
        _this.confirmSellLoadingTXT = i18n.t("methods.t3");

        // 查看代币授权给合约数量
        const allowance = new Big(await TokenContract.allowance(account, this.markerContract));
        // console.log(`allowance insufficient：allowance(${allowance}, ${_this.token_info.name}) lt payment_amount(${payment_amount}, ${_this.token_info.name})`, allowance.lt(payment_amount));
        console.log("授权额度是否充足：", allowance.gte(payment_amount));
        if (allowance.lt(payment_amount)) {
            // 代币授权中...
            _this.confirmSellLoadingTXT = i18n.t("methods.t4");
            const txApprove = await TokenContract.approve(this.markerContract, payment_amount.toString());
            await txApprove.wait();
            console.log("授权结果：", txApprove);
            if (txApprove.hash) {
                Toast(i18n.t("methods.t5"));    //"授权成功"
            } else {
                throw new Error(i18n.t("methods.t6"));      //'授权失败'
            }
        }
    }
    //判断链币余额是否充足
    async checkBalance(payment_amount, account, _this) {
        console.log(_this.token_info)
        // 查询链币余额是否足够
        const balance = new Big(await this.provider.getBalance(account));
        console.log("余额：", Number(Big(balance).div(1e18).toString()), _this.token_info.name);
        console.log("需支付金额：", Number(Big(payment_amount).div(1e18)), _this.token_info.name);
        console.log("余额是否充足：", balance.gte(payment_amount));
        if (balance.lt(payment_amount)) {
            throw new Error(i18n.t("methods.t11")); //'代币余额不足'
        }
    }
    // 出售商品
    async sell(tokenAddress, pairAddress, amount1, price1, _this) {
        console.log(tokenAddress, pairAddress, amount1, price1)
        try {
            const amount = new Big(amount1);
            const price = new Big(price1);
            // 查询目前 gas 价格
            const gasPrice = await this.provider.getGasPrice();
            // 钱包地址
            const account = await this.signer.getAddress();
            // 计算手续费
            const fee = amount.times(_this.FeeRate.sellFee);
            // 计算总开销
            const payment_amount = this.toWei(amount.plus(fee));
            // 余额检查中...
            _this.confirmSellLoadingTXT = i18n.t("methods.t1");
            // 调用合约配置
            var options = {
                gasPrice: gasPrice.add(0.2 * 1e9),
            }
            // 订单分支
            if (tokenAddress == '0x0000000000000000000000000000000000000000') {
                // 查询链币余额是否足够
                await this.checkBalance(payment_amount, account, _this)
                options.value = payment_amount.toString()
            } else {
                // 查询代币余额和授权
                await this.checkBalanceAndApprove(tokenAddress, payment_amount, account, _this);
            }
            // 执行合约中...
            _this.confirmSellLoadingTXT = i18n.t("methods.t7");

            //估算gasLimit
            const gasLimit = await this.contract.estimateGas.sellGoodsV2(tokenAddress, pairAddress, this.toWei(amount), this.toWei(price), options);
            options.gasLimit = gasLimit.add(10000);
            console.log("GasLimit:", gasLimit / 1e9, "Gwei");
            console.log("gasPrice", gasPrice / 1e9, "Gwei");

            console.log("数量：", amount.toString(), _this.token_info.name);
            console.log("单价：", price.toString(), _this.token_info.pair_name);
            console.log("手续费：", fee.toString(), _this.token_info.name);
            console.log("Send Params:", tokenAddress, pairAddress, this.toWei(amount).toString(), this.toWei(price).toString(), options);

            // 挂单
            const txSell = await this.contract.sellGoodsV2(tokenAddress, pairAddress, this.toWei(amount), this.toWei(price), options);
            await txSell.wait();
            console.log("合约执行结果：", txSell);
            return txSell;
        } catch (error) {
            this.errorHandle(error);
            throw error;
        }
    }
    // 购买商品
    async buy(order, _this) {
        console.log("orderID:", order.orderId)
        try {
            _this.confirmBuyLoadingTXT = i18n.t("methods.t12"); //验证中

            // 检查挂单ID是否存在
            const orderIndex = await this.contract.goodsIndexed(order.orderId);
            if (orderIndex == 0) {
                _this.show_buy = false;
                _this.$refs.market.onRefresh(true);
                throw new Error(i18n.t("methods.t8"));  //'订单不存在'
            }
            // 检查挂单信息是否存在
            const order_info = await this.contract.allGoods(orderIndex - 1);
            if (!order_info) {
                throw new Error(i18n.t("methods.t8"));  //'订单不存在'
            }
            // 验证是否购买自己的商品
            const account = await this.signer.getAddress();
            if (account == order.from) {
                throw new Error(i18n.t("methods.t9"));  //'不能购买自己的商品'
            }

            // 余额检查中...
            _this.confirmBuyLoadingTXT = i18n.t("methods.t10");

            // 计算支付数量
            const amount = new Big(order_info.amount);
            const price = new Big(order_info.price);
            var payment_amount = amount.times(price).times(1e-18);
            if (_this.FeeRate.buyFee > 0) {
                payment_amount = payment_amount.plus(payment_amount.times(_this.FeeRate.buyFee));
            }

            // 查询目前 gas 价格
            const gasPrice = await this.provider.getGasPrice();
            // 调用合约配置
            var options = {
                gasPrice: gasPrice.add(ethers.utils.parseUnits('0.2', 'gwei')),
            }
            if (order_info.token == '0x0000000000000000000000000000000000000000') {
                //查询需要用什么代币购买此订单
                const buy_token = await this.contract.goodsBuyToken(order.orderId);
                await this.checkBalanceAndApprove(buy_token, payment_amount, account, _this);
            } else {
                //检查链币余额
                await this.checkBalance(payment_amount, account, _this);
                options.value = payment_amount.toString()
            }

            // 执行合约中...
            _this.confirmBuyLoadingTXT = i18n.t('methods.t7');

            // //估算gasLimit
            // const gasLimit = await this.contract.estimateGas.buyGoods(order.orderId, options);
            // options.gasLimit = gasLimit.add(10000);
            // console.log("GasLimit:", gasLimit.toString());
            // console.log("gasPrice", gasPrice.toString());

            // 购买
            const txBuy = await this.contract.buyGoods(order.orderId, options);
            await txBuy.wait();
            return txBuy;
        } catch (error) {
            this.errorHandle(error);
            throw error;
        }
    }
    // 撤销订单
    async cancel(order, _this) {
        try {
            _this.cancelLoadingTXT = i18n.t("methods.t12"); //'验证中'
            const account = await this.signer.getAddress();
            // 检查挂单状态
            const orderIndex = await this.contract.goodsIndexed(order.orderId);
            console.log("orderIndex", orderIndex - 1);
            if (orderIndex == 0) {
                _this.onRefresh(true);
                throw new Error(i18n.t("methods.t13")); //'订单已取消'
            }
            const order_info = await this.contract.allGoods(orderIndex - 1);
            console.log("order_info", order_info);
            if (order_info.owner != account) {
                throw new Error(i18n.t("methods.t14")); //'只能撤销自己的订单'
            }

            // 查询目前 gas 价格
            const gasPrice = await this.provider.getGasPrice();
            // 调用合约配置
            var options = {
                gasPrice: gasPrice.add(ethers.utils.parseUnits('0.2', 'gwei')),
            }
            // 执行合约
            _this.cancelLoadingTXT = i18n.t("methods.t15");

            // //估算gasLimit
            // const gasLimit = await this.contract.estimateGas.cancelGoods(order.orderId, options);
            // options.gasLimit = gasLimit.add(10000);
            // console.log("GasLimit:", gasLimit.toString());
            // console.log("gasPrice", gasPrice.toString());

            // 撤销合约
            const txCancel = await this.contract.cancelGoods(order.orderId, options);
            await txCancel.wait();
            console.log("撤回订单结果：", txCancel);
            return txCancel;
        } catch (error) {
            this.errorHandle(error);
            throw error;
        }
    }
}

export default BlockchainContract;
